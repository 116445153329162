<template>
    <div>
        <div class="row ">
            <div class="col-12 col-md-4" v-show="stats.documentsToSign!=null">
                <div class="card p-24 text-left">
                    <div class="d-flex justify-content-between align-items-center">
                        <div>
                            <p class="card-heading-text text-nowrap">{{stats.documentsToSign}}</p>
                            <app-normal-text>Total Documents to sign</app-normal-text>
                        </div>
                        <div>
                            <img src="@/assets/images/app-icons/pen_circle.png" alt="">
                        </div> 
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4" v-show="stats.y2dDeposited!=null">
                <div class="card p-24 text-left">
                    <div class="d-flex justify-content-between align-items-center">
                        <div>
                            <p class="card-heading-text text-nowrap ">{{stats.y2dDeposited | currency}}</p>
                            <app-normal-text>Total Amount Deposited</app-normal-text>
                        </div>
                        <div>
                            <div class="bg-circle text-primary">
                                <FeatherIcon icon="BriefcaseIcon" size="22"/>
                            </div>
                            <!-- <img src="@/assets/images/app-icons/pen_circle.png" alt=""> -->
                        </div> 
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4" v-show="stats.y2dInvoiced!=null">
                <div class="card p-24 text-left">
                    <div class="d-flex justify-content-between align-items-center">
                        <div>
                            <p class="card-heading-text text-nowrap">{{stats.y2dInvoiced | currency}}</p>
                            <app-normal-text>Total Amount Invoiced</app-normal-text>
                        </div>
                        <div>
                            <img src="@/assets/images/app-icons/doc_circle.png" alt="">
                        </div> 
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4" v-show="stats.y2dReceived!=null">
                <div class="card p-24 text-left">
                    <div class="d-flex justify-content-between align-items-center">
                        <div>
                            <p class="card-heading-text text-nowrap">{{stats.y2dReceived | currency}}</p>
                            <app-normal-text>Total Amount Received</app-normal-text>
                        </div>
                        <div>
                            <img src="@/assets/images/app-icons/dollar_circle.png" alt="">
                        </div> 
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4" v-if="stats.invoicesSent!=null">
                <div class="card p-24 text-left">
                    <div class="d-flex justify-content-between align-items-center"> 
                        <div>
                            <p class="card-heading-text">{{stats.invoicesSent}}</p>
                            <app-normal-text>Invoices Sent</app-normal-text>
                        </div>
                        <div>
                            <img src="@/assets/images/app-icons/doc_circle.png" alt="">
                        </div>    
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4" v-if="stats.invoicesReceived!=null">
                <div class="card p-24 text-left">
                   
                    <div class="d-flex justify-content-between align-items-center">
                        <div>
                            <p class="card-heading-text">{{stats.invoicesReceived}}</p>
                            <app-normal-text>Invoices Recieved</app-normal-text>
                        </div>
                        <div>
                            <img src="@/assets/images/app-icons/doc_circle.png" alt="">
                        </div>    
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4" v-if="stats.totalReceivedDocuments!=null">
                <div class="card p-24 text-left">
                    <div class="d-flex justify-content-between align-items-center">
                        <div>
                            <p class="card-heading-text">{{stats.totalReceivedDocuments}}</p>
                            <app-normal-text>Documents to Review</app-normal-text>
                        </div>
                        <div > 
                            <img src="@/assets/images/app-icons/doc_circle.png" alt="">
                        </div>    
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4" v-if="stats.paymentsReceived!=null">
                <div class="card p-24 text-left">
                    <div class="d-flex justify-content-between align-items-center"> 
                        <div>
                            <p class="card-heading-text">${{stats.paymentsReceived}}</p>
                            <app-normal-text>Payments Received</app-normal-text>
                        </div>
                        <div>
                            <img src="@/assets/images/app-icons/dollar_circle.png" alt="">
                        </div>    
                    </div>
                </div>
            </div>

        </div>
        <div class="row" v-if="documentFolders.length">
            <div class="col-12">
                <p class="main-text">Project Folders</p>
            </div>
            <div class="col-12 col-md-6 col-lg-3" v-for="(f,index) in documentFolders" :key="index">
                <folder-card :name="f.name" :total="f.noOfDocuments" :owner="`${f.createdBy.first_name} ${f.createdBy.last_name}`" :collaborators="f.collaborators" @moveNext="$router.push(`/documents/${f.name}/${f._id}`)"/>
            </div>
        </div>
        
        <div class="row" v-if="recentDocuments.length">
            <div class="col-12">
                <p class="main-text">Recent Documents</p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3 col-12" v-for="(doc,index) in recentDocuments" :key="index">
                <div class="card p-12 mb-12 mb-md-3">
                    <div class="d-flex  align-items-center">
                        <div class="mr-1">
                            <img src="@/assets/images/app-icons/doc_circle.png" alt="">
                        </div> 
                        <div>
                            <p class="text-simple">{{doc.documentName}}</p>
                            <p class="text-small">{{ getFormatedDate(doc.updatedAt,"DD MMMM, YYYY") }}</p>
                        </div>   
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <p class="main-text">Documents Require Signatures</p>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <app-paginated-table :fields="fields" :items="items" :current-page="filters.page" :total-pages="total_pages" :total="total_records" :loading="loading" @onRowClicked="(e)=>viewDetails(e._id)" @onPageChange="onPageChange" :mobileHeaders="['document_name','actions']">
                    <template #header>
                        <p class="text-doc px-2 py-1">Documents</p>
                    </template>
                    <template #filters>
                        <div class="row">
                            <div class="col-md-4 col-12">
                                <app-text-input v-model="filters.search" placeholder="Search" @input="getDocuments"></app-text-input>
                            </div>
                        </div>
                    </template>
                    <template #place-right>
                        <b-button variant="primary" @click="()=>$refs.uploadDoc.showModal()">Upload Document</b-button>
                    </template>
                    <template #head(actions)>
                        {{ '' }}
                    </template>
                    <template #cell(document_name)="data">
                        <div class="d-flex">
                            <img :src="require('@/assets/images/app-icons/pdf.png')" alt="" class="mr-1">
                            <div>
                                {{ data.item.documentName }}
                            </div>
                        </div>
                    </template>
                    <template #cell(collaborator)="data">
                        <b-avatar-group size="32px">
                            <b-avatar
                            :class="[data.item.collaborators.length<2?'mx-0':'']" v-for="(c,index) in data.item.collaborators.slice(0,1)" :key="index"
                            :text="c.email.slice(0,1).toUpperCase()"
                            :variant="colors[index]"
                            v-b-tooltip.hover.v-primary="c.email"
                          />
                          <b-avatar v-if="data.item.collaborators.length > 1" 
                            class="mx-0" 
                            :text="`+${data.item.collaborators.length-1}`"
                            variant="light-success"
                            id="tooltip-avatar"
                            v-b-tooltip.hover.v-primary="getcollaborators(data.item.collaborators.slice(1))"
                            />
                            <!-- <b-tooltip target="tooltip-avatar" variant="primary"><span v-for="(c,index) in data.item.collaborators.slice(1)" :key="index">{{c.email}}<br></span></b-tooltip> -->
                        </b-avatar-group>
                    </template>
                    <template #cell(actions)="data">
                        <b-dropdown
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            text="Block Level Dropdown Menu"
                            right
                            variant="none"
                            boundary="window"
                            size="sm"
                            no-caret
                            >
                            <template #button-content>
                                <feather-icon
                                    icon="MoreVerticalIcon"
                                    size="18"
                                />
                            </template>
                            <b-dropdown-item @click="showPendingSignatures(data.item.pendingSignatures,data.item.signedBy)">Pending Signatures <b-badge class="ml-1 rounded-circle" variant="light-primary">{{data.item.pendingSignatures.length}}</b-badge></b-dropdown-item>
                            <!-- <b-dropdown-item @click="saveToBackup(data.item._id)">Save to Backups</b-dropdown-item> -->
                            <b-dropdown-item @click="getDocumnet(data.item._id)">Download</b-dropdown-item>
                        </b-dropdown>            
                    </template>
                    <template #cell(sent_by)="data">
                        <b-avatar
                            class="pull-up"
                            :text="toCapitalize(data.item.sentBy.first_name.charAt(0))"
                            variant="light-primary"
                        />
                        {{ `${toCapitalize(data.item.sentBy.first_name)} ${toCapitalize(data.item.sentBy.last_name)}` }}
                    </template>
                    <template #cell(status)="data">
                        <b-badge :variant="data.item.status=='sent'? 'light-success':data.item.status=='completed'?'light-primary':data.item.status=='denied'?'light-danger':data.item.status=='awaiting_signature'?'light-warning': 'light-secondary'">
                            {{ toCapitalize(data.item.status) }}
                        </b-badge>
                    </template>
                    <template #cell(date_uploaded)="data">
                        {{ getFormatedDate(data.item.createdAt,"DD MMM YYYY") }}
                    </template>
                    <template #cell(last_modified)="data">
                        {{ getFormatedDate(data.item.updatedAt,"DD MMM YYYY") }}
                    </template>
                </app-paginated-table>
                <upload-file ref="uploadDoc"/>
                <pending-signatures ref="pendingsignature"/>
            </div>
        </div>
    </div>
</template>
<script>
import {BTabs,BTab, BDropdown, BDropdownItem, BBadge, BAvatarGroup, BAvatar, BButton ,BTooltip, VBTooltip} from 'bootstrap-vue'
import FolderCard from '@/components/functional-components/other/documents/FolderCard.vue';
import AppHeading2 from '@/components/app-components/AppHeading2.vue';
import AppNormalText from '@/components/app-components/AppNormalText.vue'
import CreateFolderButton from '@/components/functional-components/other/documents/CreateFolderButton.vue';
import AppPaginatedTable from '@/components/app-components/AppPaginatedTable.vue';
import AppTextInput from '@/components/app-components/AppTextInput.vue';
import AppSelectBox from '@/components/app-components/AppSelectBox.vue';
import UploadFile from '@/components/functional-components/other/documents/UploadFile.vue'
import PendingSignatures from '@/components/functional-components/other/documents/PendingSignatures.vue';
import {get_folder_list,recent_documents, get_documents_require_sign,save_to_backup, get_document, get_dashboard_stats, get_y2d_stats} from '@/apis/others/documents'
import { getUserData } from '@/auth/utils'
import moment from 'moment'
import Ripple from 'vue-ripple-directive'
import { FeatherIcon } from 'vue-feather-icons';
export default {
    directives: {
        'b-tooltip': VBTooltip,
        Ripple
    },
    components: {
        BTabs,
        BTab, 
        BDropdown, 
        BDropdownItem, 
        BBadge, 
        BAvatarGroup, 
        BAvatar,
        FolderCard,
        AppHeading2,
        CreateFolderButton,
        AppPaginatedTable,
        AppTextInput,
        AppSelectBox,
        AppNormalText,
        BButton,
        UploadFile,
        PendingSignatures,
        BTooltip
    },
    data(){
        return{
            stats:{
                documentsToSign: null,
                invoicesReceived: null,
                invoicesSent: null,
                paymentsReceived: null,
                totalReceivedDocuments: null,
                y2dDeposited:null,
                y2dInvoiced:null,
                y2dReceived:null
            },
            documentType:'recieved',
            documentFolders:[],
            recentDocuments:[],
            fields:[
                {
                    key:'document_name',
                    label:'Document Name'
                },
                {
                    key:'collaborator',
                    label:'Collaborator'
                },
                {
                    key:'status',
                    label:'Status'
                },
                {
                    key:'sent_by',
                    label:'Sent By'
                },
                {
                    key:'date_uploaded',
                    label:'Date Uploaded'
                },
                {
                    key:'last_modified',
                    label:'Last Modified'
                },
                {
                    key:'actions',
                }
            ],
            items:[],
            filters:{
                limit:10,
                page:1,
                search:'',
                startDate:null,
                endDate:null,
            },
            colors:['light-primary','light-info','light-success','light-danger','light-secondary'],
            loading:false,
            current_page:1,
            total_records:0,
            total_pages:1
        }
    },
    mounted(){
        this.getFolders()
        this.getRecentDocuments()
        this.getDocuments()
        let user=getUserData()
        if(user.role=='client'){
            this.getDashboardStats()
        }else{
            this.getY2DStats()
        }
        
    },
    methods:{
        getFolders(){
            get_folder_list().then(response=>{
                this.documentFolders = response.data
                
            })
        },
        getRecentDocuments(){
            recent_documents().then(response=>{
                this.recentDocuments = response.data
            })
        },
        getFormatedDate(date,format){
            return moment(date).format(format)
        },
        getDocuments(){
            this.loading=true
            get_documents_require_sign(this.filters).then(resp=>{
                this.loading=false
                this.items = resp.data.documentsRequiringSignatures
                this.current_page=Number(resp.data.currentPage)
                this.total_records=resp.data.documentsCount
                this.total_pages=resp.data.documentsCount>0?resp.data.documentsCount/this.filters.limit:0
            }).catch(ree=>{
                this.loading=false
            })
        },
        viewDetails(id){
            this.$router.push({path:`/documents/recieved`,query:{id:id}})
        },
        toCapitalize(name){
            let words = name.split("_")
            let upperCaseStr= words.map(w=>`${w.charAt(0).toUpperCase()}${w.slice(1)}`).join(" ")
            return upperCaseStr
        },
        showPendingSignatures(pending_signatures, signed_by){
           
            let pending_sign=pending_signatures.map(s=>{
                return {
                    id:s._id,
                    name:`${s.first_name} ${s.last_name}`,
                    email:s.email,
                    signed:false
                }
            })
            let signed=signed_by.map(s=>{
                return {
                    id:s._id,
                    name:`${s.first_name} ${s.last_name}`,
                    email:s.email,
                    signed:true
                }
            })
            this.$refs.pendingsignature.showModal([...signed, ...pending_sign])
        },
        saveToBackup(id){
            save_to_backup(id).then(resp=>{
                this.$bvToast.toast(resp.message, {
                        title: 'Saved to backup',
                        variant: 'success',
                        solid: true,
                    })
            }).catch(error => {
                this.$bvToast.toast(error.message, {
                        title: error.error,
                        variant: 'danger',
                        solid: true,
                    }) 
             })
        },
        onPageChange(page){
            this.filters.page=page;
            this.getDocumnetsList(this.getDocType, this.filters)
        },
        getDocumnet(id){
            get_document(id).then(resp=>{
               this.download(resp.data.fileLink, resp.data.document.document)
            })
        },
        download(url,name){
            var link = document.createElement("a");
            link.setAttribute('download',name);
            link.href = url;
            link.target = '_blank';
            document.body.appendChild(link);
            link.click();
            link.remove();
        },
        getDashboardStats(){
            let user=getUserData()
            get_dashboard_stats({type:user.role}).then(resp=>{
                this.stats={...this.stats,...resp.data}
            })
        },
        getY2DStats(){
            get_y2d_stats().then(resp=>{
                this.stats={...this.stats,...resp.data}
            })
        },
        getcollaborators(collaborators){
                let colab=''
                collaborators.map(c=>{
                    colab=`${colab}${c.email}\n`
                });
                return colab
            }
    }
    
}
</script>
<style scoped>
.main-text{
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    color: #4B465C;
}
.p-2rem{
    padding-bottom: 2rem;
}
.card-heading-text{
    font-size: 22px;
    line-height: 30px;
    font-weight: 500;
    color: #4B465C;
    margin: 0px;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}
.p-24{
    padding:24px;
}
.p-12{
    padding: 12px;
}
.text-simple{
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: #4B465C;
    margin:0px;
}
.text-small{
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    color: #4B465C;
    margin:0px;
}

.text-doc{
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    color: #4B465C;
    margin:0px;
}
.mb-12{
    margin-bottom: 12px !important;
}
.bg-circle{
    border-radius: 50%;
    padding: 8px;
    background-color:#f2f6ff; 
    opacity: .9;
}
.font-arial{
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  }
</style>